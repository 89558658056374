import React from "react";
import  fabrication from "./img/mbg.png";
import {Form, Input,Button} from 'antd';
import TextArea from "antd/lib/input/TextArea";
import PhoneInput from 'react-phone-input-2'

import { MDBCard, MDBCardBody, MDBContainer,MDBCol,MDBRow,MDBIcon} from "mdbreact";
import { enquireScreen } from 'enquire-js';
import Texty from 'rc-texty';

const axios = require('axios');
let isMobile;
let rbg;
rbg={height:"80vh",width:"100%",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center",background:" -webkit-radial-gradient(bottom, grey , black )",backgroundColor: "#999"}
enquireScreen((b) => {
  isMobile = b;
});
let smobi;
if(isMobile){
   smobi={
    padding:"0px"
  }
}
if(isMobile){
  rbg={height:"100vh",width:"100%",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center",background:" -webkit-radial-gradient(bottom, grey , black )",backgroundColor: "#999"}
}
const ori="vertical";
const mButton = {
  display: "block",
  margin: "72px auto 0",
  background:" rgb(3, 67, 101)",
  background: "-moz-linear-gradient(315deg, rgb(63, 13, 18) 0%, rgb(167, 29, 49) 74%)",
  background: "linear-gradient(315deg, rgb(63, 13, 18) 0%, rgb(167, 29, 49) 74%)",
  boxShadow: "0px 4px 5px 0px #9da8b6",
  border: "none",
  transition:" background .45s @ease-out",
  width: "132px",
  lineHeight: "42px",
  height: "42px",
  marginTop:"22px",
  borderRadius: "42px",
};
class Banner1 extends React.PureComponent {
  state = {
    st: "",
    mc:"white",
   
  }
  async onSubmit(e){
    e.preventDefault() 
    let res = await this.uploadFile(this.state.file);
    console.log(res.data);
    console.log(res.data.url);
    this.props.form.setFieldsValue({
      ['urli']: res.data.url,
  });
  if(this.state.imr & !res.data.error & res.data.error!=undefined || !this.state.imr){
    console.log("error"+res.data.error);
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        return;
      }
      const values = {
        fieldsValue
      };
      console.log('Received values of form: ', values);
      axios({
        method: 'post',
        // url: 'http://localhost/nw/vap/regApi.php?apicall=signup'
        url: 'http://lmgexhibitions.kilaqitu.com/php/lmg/api.php?apicall=quote',
        data: values,
        config: { headers: {'Content-Type': 'multipart/form-data' }}
    })
    .then((response) => {
      // console.log("response "+JSON.stringify(response));
      if(response.data.error){
        console.log("error "+response.data.message);
        this.setState({
          dbMessage: response.data.message
       });
        }else{
          if(response.data.fw){
            this.setState({
              ds: "none", 
              ds1: "block"
           });

          }
          console.log("msg "+response.data.message);
          this.setState({
            dbMessage: response.data.message
         });
        // var result=response.data.message;
        // JSON.stringify(result)
        // console.log("success "+JSON.stringify(result));
        }

    })
    .catch(function (response) {
        //handle error
        console.log("error"+response)
    });
    });
  }
}
  render() {
    const { ...currentProps } = this.props;
    const { dataSource } = currentProps;
    delete currentProps.dataSource;
    const { getFieldDecorator,} = this.props.form;
    // delete currentProps.isMobile;
    if(currentProps.isMobile){
   
this.setState({
  mc:"black",

})
    }
    return (
      <div  style={{backgroundColor:"transparent",backgroundAttachment: "fixed",backgroundSize:"cover",backgroundPosition: "center" ,marginTop:"-2px"}}>
      <div style={rbg}>
 

          <div style={{color:"white",paddingTop:"30vh",marginLeft:"auto",marginRight:"auto",}}>
          
          <h5 style={{textShadow:"0 1px 0 #ccc,0 2px 0 #c9c9c9,0 3px 0 #bbb,0 4px 0 #b9b9b9,0 5px 0 #aaa,0 6px 1px rgba(0,0,0,.1),0 0 5px rgba(0,0,0,.1),0 1px 3px rgba(0,0,0,.3),0 3px 5px rgba(0,0,0,.2),0 5px 10px rgba(0,0,0,.25),0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15)",color: "white",fontSize: "68px",lineHeight: "80px",textIndent: "2px",fontWeight: "600"}}> <Texty style={{fontSize: "58px",lineHeight: "80px", textIndent: "2px",fontWeight: "600",margin: "26px auto 38px",overflow: "hidden"}} className="text-center"type="mask-bottom">Peter Njoroge</Texty></h5>
          </div>
      
    
      </div>

      <MDBCard style={{ width:"100%",maxWidth: "1400px",marginRight: "auto",marginLeft: "auto",paddingBottom:"7px",marginTop:"-3px",backgroundSize:"cover",background:"linear-gradient(146deg, rgb(44, 62, 80) 8%, black 8%, black 25%, white 9%, white 92%, black 7%, black 98%, rgb(44, 62, 80) 1%) center center / cover fixed"}} className="text-center">
  <MDBCardBody  style={smobi}>
<MDBCard style={{ width:"100%",maxWidth: "1400px",marginRight: "auto",marginLeft: "auto",marginBottom:"17px",paddingBottom:"37px",marginTop:"3px",backgroundSize:"cover",background:"linear-gradient(147deg, rgba(8, 0, 0, 0.01) 10%, rgb(255, 255, 255) 4%, rgb(255, 255, 255) 97%, rgba(0, 0, 0, 0.06) 4%)",backgroundAttachment: "fixed",backgroundPosition: "center"}} className="text-center">
  <MDBCardBody  style={smobi}>

<MDBCard color="white lighten-1" style={{marginTop:"82px",maxWidth:"900px",marginLeft:"auto",marginRight:"auto",boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)",padding:"35px",marginBottom:"22px"}} text="black" className="text-center">
  <MDBCardBody style={smobi}>
  <h5 style={{marginTop:"-43px"}}>About myself</h5>
  <br/>
<p>I am Peter Njoroge, a problem solver, an indivisdual who likes to tackle problems and come up with solutions using computer programs, mobile applications or websites. I have experience developing responive websites using various technologies including react js, react native, vue, nuxt laravel and material design. I also have experience developing mobile applications using android studio and java as well as developing computer programs for windows using c#.

</p>
<br/> <br/>
<p>
I have worked with python, java, c#, php and javascript. I am also skilled in design including the adobe suit which includes adobe illustrator and adobe photoshop. I am open to learning new things and learning from the experiences, new challenges have to offer. </p>
<br/> <br/>
  <MDBCard color="white lighten-1" style={{marginLeft:"auto",marginRight:"auto",boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)",marginBottom:"-103px"}} text="black" className="text-center">
    <MDBCardBody style={{color:"#697b8c"}} >
     
   
    </MDBCardBody>
  </MDBCard>

</MDBCardBody>
</MDBCard>
<MDBContainer>
      <MDBRow>
        <MDBCol md="6">
        <a href="tel:0713772837">
          <MDBCard color="white lighten-1" style={{boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)",maxWidth:"800px",marginLeft:"auto",marginRight:"auto",marginTop:"32px"}} text="black" className="text-center">
            <MDBIcon icon="mobile-alt" size="2x"  className="indigo-text pr-3"/>
          <MDBCardBody style={{color:"#697b8c"}}>
            <h5 style={{}}>Mobile </h5>
            <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >Phone: +254-713-772-837</p> 
           
              <br/>
          
          </MDBCardBody>
        </MDBCard>
        </a>
        </MDBCol>
    
        <MDBCol md="6">
        <MDBCard color="white lighten-1" style={{boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)",maxWidth:"800px",marginLeft:"auto",marginRight:"auto",marginTop:"32px"}} text="black" className="text-center">
            <MDBIcon icon="envelope" size="2x"  className="indigo-text pr-3"/>
          <MDBCardBody style={{color:"#697b8c"}}>
            <h5 style={{}}>Email </h5>
            <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >Email: vector.pn@gmail.com</p> 
              <br/>
        
          
          </MDBCardBody>
        </MDBCard>
        </MDBCol>
        
      </MDBRow>
     
    </MDBContainer>
  
</MDBCardBody>
</MDBCard>
  </MDBCardBody>
</MDBCard> 
  </div>
    );
  }
}
const Banner = Form.create({ name: 'time_related_controls' })(Banner1);
export default Banner;
