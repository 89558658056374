import React from 'react';
import { Button, Icon } from 'antd';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import Texty from 'rc-texty';
import "../../../node_modules/video-react/dist/video-react.css";
import { MDBCard, MDBCardBody, MDBMask,MDBCol,MDBRow,MDBCarousel,MDBContainer,MDBCarouselInner,MDBCarouselItem,MDBView,MDBCarouselCaption,MDBCardVideo,MDBCardTitle,MDBCardText,MDBBtn} from "mdbreact";

import 'react-html5video/dist/styles.css';
import { Player } from 'video-react';
import vid from "../vid/f.mp4";
import { DefaultPlayer as Video } from 'react-html5video';
import vote from "../vid/vote.mp4";
import qr from "../vid/qr.mp4";
import bank from "../vid/bank.mp4";

import silai2 from "../img/mf/silai2.jpg";
import losen from "../img/7.jpg";
import LMG from "../img/3.jpg";
import {Redirect,Link } from "react-router-dom";
import  fabrication from "../img/1.jpg";
import  l1 from "../img/l1.png";
import  l2 from "../img/l2.png";

// import  im1 from "../img/im1.jpg";

import  im1 from "../img/7.jpg";
import  im2 from "../img/8.jpg";
import  im3 from "../img/3.jpg";
import  im4 from "../img/4.jpg";
import  im5 from "../img/5.jpg";
import  im6 from "../img/6.jpg";
import  im7 from "../img/7.jpg";
import  im8 from "../img/8.jpg";

import "react-multi-carousel/lib/styles.css";

import '../Home/static/style';

import {Form, Input,Radio} from 'antd';
import { enquireScreen } from 'enquire-js';
let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
const axios = require('axios');
const ori="vertical";
let shad;
shad={
  // boxShadow: "rgba(34, 94, 222, 0.08) 0px 32px 32px",
  marginTop:"72px",
  paddingBottom:"22px"
}
let shad1={
  boxShadow: "rgba(34, 94, 222, 0.08) 0px 32px 32px"
}
let shad2={
  boxShadow: "rgba(34, 94, 222, 0.08) 0px 32px 32px",
  maxWidth:"700px",
  marginLeft:"auto",marginRight:"auto",
}
let smobi;
let smobi1;

if(isMobile){
   smobi={
    padding:"0px"
  }
  smobi1={
    paddingLeft:"3px",
    paddingRight:"13px"
  }
}
let rbg1;
rbg1={height:"70vh",width:"100%",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center",backgroundImage:"url("+im1+")"}

let rbg2;
rbg2={height:"70vh",width:"100%",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center",backgroundImage:"url("+im2+")"}

let rbg3;
rbg3={height:"70vh",width:"100%",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center",backgroundImage:"url("+im3+")"}
let rbg4;
rbg4={height:"70%",width:"100%",maxWidth: "587px",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center",backgroundImage:"url("+im4+")"}
let rbg5;
rbg5={height:"70vh",width:"100%",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center",backgroundImage:"url("+im5+")"}
let rbg6;
rbg6={height:"70vh",width:"100%",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center",backgroundImage:"url("+im6+")"}
let rbg7;
rbg7={height:"70vh",width:"100%",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center",backgroundImage:"url("+im7+")"}
let rbg8;
rbg8={height:"70vh",width:"100%",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center",backgroundImage:"url("+im8+")"}
if(isMobile){
  rbg1={height:"100vh",width:"100%",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center",backgroundImage:"url("+fabrication+")"}
  
  rbg1={height:"100vh",width:"100%",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center",backgroundImage:"url("+im1+")"}
  
  rbg2={height:"100vh",width:"100%",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center",backgroundImage:"url("+l2+")"}
}

const mButton = {
  display: "block",
  margin: "12px auto 0",
  background:" rgb(3, 67, 101)",
  background: "linear-gradient(to right, #034365 0%, #001b33 100%)",
  boxShadow:"0 8px 16px #0a52ab",
  // background: "-moz-linear-gradient(315deg, #3f0d12 0%, #a71d31 74%)",
  // background: "linear-gradient(315deg, #3f0d12 0%, #a71d31 74%)",
  // boxShadow: "rgba(38, 3, 3, 0.36) 1px 5px 5px",
  border: "none",
  transition:" background .45s @ease-out",
  width: "132px",
  lineHeight: "42px",
  height: "42px",
  borderRadius: "42px",


};
const responsive1 = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};
class Banner1 extends React.PureComponent {
  state = {
    st: "",
    mc:"white",
   
  }
  async onSubmit(e){
    e.preventDefault() 
    let res = await this.uploadFile(this.state.file);
    console.log(res.data);
    console.log(res.data.url);
    this.props.form.setFieldsValue({
      ['urli']: res.data.url,
  });
  if(this.state.imr & !res.data.error & res.data.error!=undefined || !this.state.imr){
    console.log("error"+res.data.error);
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        return;
      }
      const values = {
        fieldsValue
      };
      console.log('Received values of form: ', values);
      axios({
        method: 'post',
        // url: 'http://localhost/nw/vap/regApi.php?apicall=signup'
        url: 'http://lmgexhibitions.kilaqitu.com/php/lmg/api.php?apicall=quote',
        data: values,
        config: { headers: {'Content-Type': 'multipart/form-data' }}
    })
    .then((response) => {
      // console.log("response "+JSON.stringify(response));
      if(response.data.error){
        console.log("error "+response.data.message);
        this.setState({
          dbMessage: response.data.message
       });
        }else{
          if(response.data.fw){
            this.setState({
              ds: "none", 
              ds1: "block"
           });

          }
          console.log("msg "+response.data.message);
          this.setState({
            dbMessage: response.data.message
         });
        // var result=response.data.message;
        // JSON.stringify(result)
        // console.log("success "+JSON.stringify(result));
        }

    })
    .catch(function (response) {
        //handle error
        console.log("error"+response)
    });
    });
  }
}
  render() {
    const { ...currentProps } = this.props;
    const { dataSource } = currentProps;
    delete currentProps.dataSource;
    const { getFieldDecorator,} = this.props.form;
    // delete currentProps.isMobile;
    if(currentProps.isMobile){
   
this.setState({
  mc:"black",

})
    }
    return (
      <div {...currentProps} {...dataSource.wrapper} style={{height:this.state.st}}>

<div {...dataSource.page} style={smobi1}>
<div  style={{background:"linear-gradient(146deg, rgb(27 34 41) 8%, black 8%, black 5%, white 9%, white 95%, black 96%, black 9%, rgb(27 34 41) 1%)",backgroundAttachment: "fixed",backgroundSize:"cover",backgroundPosition: "center",}}>

          

        {isMobile ? (
          <div {...dataSource.page} style={{marginLeft:"auto",marginRight:"auto",boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)"}} text="black" className="text-center">
          <MDBContainer>
            <MDBRow >
              <MDBCol md="6">
           <h5 style={{paddingTop:"12px",paddingBottom:"22px"}}>Voting application</h5>  
           <Video style={{width:"99%",maxWidth: "587px",margin:"auto"}} loop muted
                        controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                        poster="http://sourceposter.jpg"
                        onCanPlayThrough={(e) => {
                            // Do stuff
                            e.currentTime=30;
                        }}>
                        <source src={vote} type="video/webm" />
                      
                    </Video>
              </MDBCol>
              <MDBCol md="6">
              <MDBCard color="white lighten-1" style={{boxShadow:"rgba(34, 94, 222, 0.08) 0px 32px 32px",maxWidth:"700px",marginLeft:"auto",marginRight:"auto",}} text="black" className="text-center">
           <MDBCardBody style={{color:"#697b8c"}}>
             <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >
               The voting application was a school project to help students to conduct student elections in a fast and secure manner. The program featues a simple login page as welll as sample candidates for various posts. It also has a statistics page where people can follow on the progress of the election and how each candidate is doing.
               <br></br>
              The software was developed using the .NET framework with Visual Studio IDE and php as the back end. It helped me to improve skills associated with  C# as well as WPF XAML technologies which was used to develop the grephical interface of the program. I also gained some understanding regarding WPF element animations and utilization of external modules such as php connector to save data to a database.
              <br></br>
              </p>
              
           </MDBCardBody>
         </MDBCard>
              </MDBCol>
            </MDBRow>
            <MDBRow style={{marginTop:"72px"}}>
            <MDBCol md="6">
         <h5 style={{paddingTop:"12px",paddingBottom:"22px"}}>QR software</h5>
         <Video style={{width:"99%",maxWidth: "587px",margin:"auto"}} loop muted
                        controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                        poster="http://sourceposter.jpg"
                        onCanPlayThrough={(e) => {
                            // Do stuff
                            e.currentTime=30;
                        }}>
                        <source src={qr} type="video/webm" />
                      
                    </Video>
              </MDBCol>
              <MDBCol md="6">
              <MDBCard color="white lighten-1" style={{boxShadow:"rgba(34, 94, 222, 0.08) 0px 32px 32px",maxWidth:"700px",marginLeft:"auto",marginRight:"auto",}} text="black" className="text-center">
           <MDBCardBody style={{color:"#697b8c"}}> 
           <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >
             The software was a school project to help students sign in attendance by simply using qr printed cards. The program relies on a camera to take a snapshot of a qr which it can then use to identify and register a studen as having attended a class that day.
             <br/><br/>
             The program was developed using C# and it helped me to increase my understanding of opencv and working with computer peripherals. 
              </p>

           
           </MDBCardBody>
         </MDBCard>
              </MDBCol>
            
            </MDBRow>
          </MDBContainer>
          <MDBContainer>
         
            
            <MDBRow style={{marginTop:"72px"}}>
              <MDBCol md="6">
           <h5 style={{paddingTop:"12px",paddingBottom:"22px"}}>Banking software</h5>
              <Video style={{width:"99%",maxWidth: "587px",margin:"auto"}} loop muted
                        controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                        poster="http://sourceposter.jpg"
                        onCanPlayThrough={(e) => {
                            // Do stuff
                            e.currentTime=30;
                        }}>
                        <source src={bank} type="video/webm" />
                      
                    </Video>
              </MDBCol>
              <MDBCol md="6">
              <MDBCard color="white lighten-1" style={{boxShadow:"rgba(34, 94, 222, 0.08) 0px 32px 32px",maxWidth:"700px",marginLeft:"auto",marginRight:"auto",}} text="black" className="text-center">
           <MDBCardBody style={{color:"#697b8c"}}> 
             <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >This program was developed to simulate interest to be payed on various amounts deposited to a bank for a period of one year.
            <br/><br/>
            The program was developed with python and helped me to increase my understanding of the language especially in developing object oriented python programs and utility of tkinter in teh development of grapgical user interfaces.</p>
       
           </MDBCardBody>
         </MDBCard>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          <br/><br/>
            </div>
         ):(
          
      <MDBCard color="white lighten-1" style={{background: "linear-gradient(147deg, rgba(8, 0, 0, 0.01) 3%, #ffffff 3%, #ffffff 77%, #000000 4%)",width: "calc(100% - 112px)",margin:"auto",borderRadius: "4px",padding:"22px", boxShadow: "rgba(34, 94, 222, 0.08) 0px 32px 32px"}} text="black" className="text-center">
      <MDBCardBody style={{backgroundColor: "#fff"}}>
        <div {...dataSource.page} style={{marginLeft:"auto",marginRight:"auto",boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)"}} text="black" className="text-center">
   
     <MDBContainer>
      
       <MDBRow style={shad}>
         <MDBCol md="6">
           <MDBCard color="white lighten-1" style={shad2} text="black" className="text-center">
           <MDBCardBody style={{color:"#697b8c",}}>
             
         <h5 style={{}}>Voting application</h5>
             <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >
               The voting application was a school project to help students to conduct student elections in a fast and secure manner. The program featues a simple login page as welll as sample candidates for various posts. It also has a statistics page where people can follow on the progress of the election and how each candidate is doing.
               <br></br>
              The software was developed using the .NET framework with Visual Studio IDE and php as the back end. It helped me to improve skills associated with  C# as well as WPF XAML technologies which was used to develop the grephical interface of the program. I also gained some understanding regarding WPF element animations and utilization of external modules such as php connector to save data to a database.
              <br></br>
              </p>
        
           </MDBCardBody>
         </MDBCard>
         </MDBCol>
         <MDBCol md="6"  style={shad1}>
         {/* <div >  <img className="img-fluid" style={{width:"99%",maxWidth: "587px"}} src={silai2}  /></div> */}
         <Video style={{width:"99%",maxWidth: "587px",margin:"auto"}} loop muted
                        controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                        poster="http://sourceposter.jpg"
                        onCanPlayThrough={(e) => {
                            // Do stuff
                            e.currentTime=30;
                        }}>
                        <source src={vote} type="video/webm" />
                      
                    </Video>
         </MDBCol>
       </MDBRow>
     </MDBContainer>
     <MDBContainer>
       <MDBRow style={shad}>
         <MDBCol md="6" style={shad1}>
         <h5 style={{paddingTop:"12px",paddingBottom:"22px"}}>QR software</h5>
         <div style={{paddingBottom:"22px"}}>  
         <Video style={{width:"99%",maxWidth: "587px",margin:"auto"}} loop muted
                        controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                        poster="http://sourceposter.jpg"
                        onCanPlayThrough={(e) => {
                            // Do stuff
                            e.currentTime=30;
                        }}>
                        <source src={qr} type="video/webm" />
                      
                    </Video>
          
         </div> 
         </MDBCol>
         <MDBCol md="6">
         <MDBCard color="white lighten-1" style={shad2} text="black" className="text-center">
           <MDBCardBody style={{color:"#697b8c"}}>
             <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >
             The software was a school project to help students sign in attendance by simply using qr printed cards. The program relies on a camera to take a snapshot of a qr which it can then use to identify and register a studen as having attended a class that day.
             <br/><br/>
             The program was developed using C# and it helped me to increase my understanding of opencv and working with computer peripherals. 
              </p>

           </MDBCardBody>
         </MDBCard>
         </MDBCol>
       </MDBRow>
       <MDBRow style={{marginTop:"72px"}}>
         <MDBCol md="6">
           <MDBCard color="white lighten-1" style={{boxShadow:"rgba(34, 94, 222, 0.08) 0px 2px 0px",maxWidth:"700px",marginLeft:"auto",marginRight:"auto",}} text="black" className="text-center">
           <MDBCardBody style={{color:"#697b8c"}}>
             <h5 style={{paddingTop:"12px",paddingBottom:"22px"}}>Banking software</h5>
             <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >This program was developed to simulate interest to be payed on various amounts deposited to a bank for a period of one year.
            <br/><br/>
            The program was developed with python and helped me to increase my understanding of the language especially in developing object oriented python programs and utility of tkinter in teh development of grapgical user interfaces.</p>
          
           
           </MDBCardBody>
         </MDBCard>
         </MDBCol>
         <MDBCol md="6">
         <Video style={{width:"99%",maxWidth: "587px",margin:"auto"}} loop muted
                        controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                        poster="http://sourceposter.jpg"
                        onCanPlayThrough={(e) => {
                            // Do stuff
                            e.currentTime=30;
                        }}>
                        <source src={bank} type="video/webm" />
                      
                    </Video>
         </MDBCol>
       </MDBRow>
     </MDBContainer>
       </div>
       
        </MDBCardBody>
        </MDBCard>
  )}
        </div>
      </div>
        
{/* dummy */}

 
{/* 
    <img style={{width:"120px",borderRadius:"50%"}} src="http://lmgexhibitions.kilaqitu.com/img/a.gif" alt="img" />
     */}
    {/* <h5 type="mask-bottom" style={{color:this.state.mc}}>The best at events and exhibitions</h5> */}
    
        <TweenOne
          animation={{
            y: '-=20',
            yoyo: true,
            repeat: -1,
            duration: 1000,
          }}
          className="banner0-icon"
          key="icon"
        >
        </TweenOne>


        
      </div>
      
    );
  }
}
const Banner = Form.create({ name: 'time_related_controls' })(Banner1);
export default Banner;
