import React from 'react';
import { Button, Icon } from 'antd';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { isImg } from './utils';
import Texty from 'rc-texty';
import "../../../node_modules/video-react/dist/video-react.css";
import { MDBCard, MDBCardBody, MDBMask,MDBCol,MDBRow,MDBCarousel,MDBContainer,MDBCarouselInner,MDBCarouselItem,MDBView,MDBCarouselCaption,MDBCardVideo,MDBCardTitle,MDBCardText,MDBBtn} from "mdbreact";
import { DefaultPlayer as Video } from 'react-html5video';
import '../../../node_modules/react-html5video/dist/styles.css';
import { Player } from 'video-react';
import vid from "../vid/f.mp4";

import Chip from '@material-ui/core/Chip';
import  web from "../img/l2.png";
import  soft from "../img/l2.png";
import  mobi from "../img/l2.png";
import  micro from "../img/l2.png";
import silai1 from "../img/mf/silai1.jpg";
import silai2 from "../img/mf/silai2.jpg";
import jana from "../img/mf/jana.png";
import jana1 from "../img/mf/jana1.png";
import jana2 from "../img/mf/jana2.png";
import jana3 from "../img/mf/jana3.png";


import claudia from "../img/mf/claudia.png";
import claudia1 from "../img/mf/claudia1.png";
import claudia2 from "../img/mf/claudia2.png";
import claudia3 from "../img/mf/claudia3.png";

import losen from "../img/7.jpg";
import LMG from "../img/3.jpg";
import RONIC from "../img/4r.png";
import Content3 from './Content3';
import {Content30DataSource,} from './data.source';
import {Redirect,Link } from "react-router-dom";
import PhoneInput from 'react-phone-input-2'
import  fabrication from "../img/1.jpg";
import  l1 from "../img/l1.png";
import  l2 from "../img/l2.png";
import "./m.css";

// import  im1 from "../img/im1.jpg";

import  im1 from "../img/7.jpg";
import  im2 from "../img/8.jpg";
import  im3 from "../img/3.jpg";
import  im4 from "../img/4.jpg";
import  im5 from "../img/5.jpg";
import  im6 from "../img/6.jpg";
import  im7 from "../img/7.jpg";
import  im8 from "../img/8.jpg";

import "../../../node_modules/react-multi-carousel/lib/styles.css";



import Footer0 from './Footer0';
import './static/style';

import {Footer00DataSource,} from './data.source';
import {Form, Input,Radio} from 'antd';
import { enquireScreen } from 'enquire-js';
let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
const axios = require('axios');
const ori="vertical";
let shad;
shad={
  // boxShadow: "rgba(34, 94, 222, 0.08) 0px 32px 32px",
  marginTop:"72px",
  paddingBottom:"22px"
}
let shad1={
  boxShadow: "rgba(34, 94, 222, 0.08) 0px 32px 32px"
}
let shad2={
  boxShadow: "rgba(34, 94, 222, 0.08) 0px 32px 32px",
  maxWidth:"700px",
  marginLeft:"auto",marginRight:"auto",
}
let smobi;
let smobi1;

if(isMobile){
   smobi={
    padding:"0px"
  }
  smobi1={
    paddingLeft:"3px",
    paddingRight:"13px"
  }
}
let rbg1;
rbg1={height:"70vh",width:"100%",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center",backgroundImage:"url("+im1+")"}

let rbg2;
rbg2={height:"70vh",width:"100%",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center",backgroundImage:"url("+im2+")"}

let rbg3;
rbg3={height:"70vh",width:"100%",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center",backgroundImage:"url("+im3+")"}
let rbg4;
rbg4={height:"70%",width:"100%",maxWidth: "587px",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center",backgroundImage:"url("+im4+")"}
let rbg5;
rbg5={height:"70vh",width:"100%",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center",backgroundImage:"url("+im5+")"}
let rbg6;
rbg6={height:"70vh",width:"100%",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center",backgroundImage:"url("+im6+")"}
let rbg7;
rbg7={height:"70vh",width:"100%",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center",backgroundImage:"url("+im7+")"}
let rbg8;
rbg8={height:"70vh",width:"100%",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center",backgroundImage:"url("+im8+")"}
if(isMobile){
  rbg1={height:"100vh",width:"100%",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center",backgroundImage:"url("+fabrication+")"}
  
  rbg1={height:"100vh",width:"100%",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center",backgroundImage:"url("+im1+")"}
  
  rbg2={height:"100vh",width:"100%",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center",backgroundImage:"url("+l2+")"}
}


const mButton1 = {
  display: "block",
  margin: "12px auto 0",
  background:" rgb(3, 67, 101)",
  background: "linear-gradient(315deg, #09141d 0%, #223343 74%)",
  // background: "-moz-linear-gradient(357deg, rgb(0 0 0) 0%, rgb(64 118 117) 94%)",
  // background:"linear-gradient(357deg, rgb(0 0 0) 0%, rgb(64 118 117) 94%)",
  // background: "linear-gradient(315deg, #3f0d12 0%, #a71d31 74%)",
  boxShadow: "rgba(38, 3, 3, 0.36) 1px 5px 5px",
  border: "none",
  transition:" background .45s @ease-out",
  width: "132px",
  lineHeight: "42px",
  height: "42px",
  borderRadius: "42px",


};

class Banner1 extends React.PureComponent {
  state = {
    st: "",
    mc:"white",
   
  }
  async onSubmit(e){
    e.preventDefault() 
    let res = await this.uploadFile(this.state.file);
    console.log(res.data);
    console.log(res.data.url);
    this.props.form.setFieldsValue({
      ['urli']: res.data.url,
  });
  if(this.state.imr & !res.data.error & res.data.error!=undefined || !this.state.imr){
    console.log("error"+res.data.error);
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        return;
      }
      const values = {
        fieldsValue
      };
      console.log('Received values of form: ', values);
      axios({
        method: 'post',
        // url: 'http://localhost/nw/vap/regApi.php?apicall=signup'
        url: 'http://lmgexhibitions.kilaqitu.com/php/lmg/api.php?apicall=quote',
        data: values,
        config: { headers: {'Content-Type': 'multipart/form-data' }}
    })
    .then((response) => {
      // console.log("response "+JSON.stringify(response));
      if(response.data.error){
        console.log("error "+response.data.message);
        this.setState({
          dbMessage: response.data.message
       });
        }else{
          if(response.data.fw){
            this.setState({
              ds: "none", 
              ds1: "block"
           });

          }
          console.log("msg "+response.data.message);
          this.setState({
            dbMessage: response.data.message
         });
        // var result=response.data.message;
        // JSON.stringify(result)
        // console.log("success "+JSON.stringify(result));
        }

    })
    .catch(function (response) {
        //handle error
        console.log("error"+response)
    });
    });
  }
}
  render() {
    const { ...currentProps } = this.props;
    const { dataSource } = currentProps;
    delete currentProps.dataSource;
    const { getFieldDecorator,} = this.props.form;
    // delete currentProps.isMobile;
    if(currentProps.isMobile){
   
this.setState({
  mc:"black",

})
    }
    return (
      <div {...currentProps} {...dataSource.wrapper} style={{height:this.state.st}}>
 

<div {...dataSource.page} style={smobi1}>
<div  style={{background:"linear-gradient(146deg, rgb(27 34 41) 8%, black 8%, black 5%, white 9%, white 95%, black 96%, black 9%, rgb(27 34 41) 1%)",backgroundAttachment: "fixed",backgroundSize:"cover",backgroundPosition: "center",}}>

          

        {isMobile ? (
          <div {...dataSource.page} style={{marginLeft:"auto",marginRight:"auto",boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)"}} text="black" className="text-center">
          <MDBContainer>
            
            <MDBRow >


            <MDBCol md="6"  style={shad1}>
         <h5 style={{paddingTop:"72px",paddingBottom:"2px"}}>Claudia</h5>
         {/* <div >  <img className="img-fluid" style={{width:"99%",maxWidth: "587px"}} src={silai2}  /></div> */}
        
<MDBCard color="white lighten-1" style={{ maxWidth:"700px",marginLeft:"auto",marginRight:"auto",marginTop:"32px"}} text="black" className="text-center">
          <MDBCarousel
            activeItem={1}
            length={4}
            showControls={true}
            showIndicators={false}
            className="z-depth-1"
            style={{height: "100",width: "100%",size: "cover",backgroundPosition: "center center"}}
          >
            <MDBCarouselInner>
              <MDBCarouselItem itemId="1">
                <MDBView >
                <div class="bgImg" style={{backgroundImage:`url(${claudia})`}}></div>
                </MDBView>
              </MDBCarouselItem>
              <MDBCarouselItem itemId="2">
                <MDBView >
                <div class="bgImg" style={{backgroundImage:`url(${claudia1})`}}></div>
                </MDBView>
              </MDBCarouselItem>
              <MDBCarouselItem itemId="3">
                <MDBView >
                <div class="bgImg" style={{backgroundImage:`url(${claudia2})`}}></div>
                </MDBView>
              </MDBCarouselItem>
              <MDBCarouselItem itemId="4">
                <MDBView >
                <div class="bgImg" style={{backgroundImage:`url(${claudia3})`}}></div>
                </MDBView>
              </MDBCarouselItem>

           
            </MDBCarouselInner>
    </MDBCarousel>
            {/* <MDBCardBody style={{color:"#697b8c"}}>
              <h5 style={{}}>Mechanical Ventilation</h5>
              <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >Design, installation and maintainance of ventillation systems</p>
            </MDBCardBody> */}
          </MDBCard>
         </MDBCol>

                  <MDBCol md="6">
           <MDBCard color="white lighten-1" style={shad2} text="black" className="text-center">
           <MDBCardBody style={{color:"#697b8c",}}>
             
             <p style={{whiteSpace:"pre-wrap",marginTop:"52px"}} >
              The wesbite is an upgrade to an existing website. It is built using nuxt js, and laravel as the back end. In the project i chose nuxt for enhnced seo optimization. Laravel was used to build the backend for the admin dashboard used by the client to perform crud operations to the public website.
              <br></br>
           .</p>
           upgrade under construction
             <a target="blank" href="https://claudia.losenviskas.com/">
              <MDBBtn tag="a" size="sm" floating gradient="red" style={mButton1}>
                visit webite
              </MDBBtn></a>
        
           </MDBCardBody>
         </MDBCard>
         </MDBCol>
              </MDBRow>
            
          <MDBRow >
           
      <MDBCol md="6"  style={shad1}>
        <h5 style={{paddingTop:"22px",paddingBottom:"2px"}}>Jana Engineering</h5>
        {/* <div >  <img className="img-fluid" style={{width:"99%",maxWidth: "587px"}} src={jana}  /></div> */}
      
<MDBCard color="white lighten-1" style={{ maxWidth:"700px",marginLeft:"auto",marginRight:"auto",marginTop:"32px"}} text="black" className="text-center">
          <MDBCarousel
            activeItem={1}
            length={4}
            showControls={true}
            showIndicators={false}
            className="z-depth-1"
            style={{height: "100",width: "100%",size: "cover",backgroundPosition: "center center"}}
          >
            <MDBCarouselInner>
              <MDBCarouselItem itemId="1">
                <MDBView >
                <div class="bgImg" style={{backgroundImage:`url(${jana})`}}></div>
                </MDBView>
              </MDBCarouselItem>
              <MDBCarouselItem itemId="2">
                <MDBView >
                <div class="bgImg" style={{backgroundImage:`url(${jana1})`}}></div>
                </MDBView>
              </MDBCarouselItem>
              <MDBCarouselItem itemId="3">
                <MDBView >
                <div class="bgImg" style={{backgroundImage:`url(${jana2})`}}></div>
                </MDBView>
              </MDBCarouselItem>
              <MDBCarouselItem itemId="4">
                <MDBView >
                <div class="bgImg" style={{backgroundImage:`url(${jana3})`}}></div>
                </MDBView>
              </MDBCarouselItem>

           
            </MDBCarouselInner>
    </MDBCarousel>
    
          </MDBCard>

        </MDBCol>
              <MDBCol md="6">
              <MDBCard color="white lighten-1" style={{boxShadow:"rgba(34, 94, 222, 0.08) 0px 32px 32px",maxWidth:"700px",marginLeft:"auto",marginRight:"auto",}} text="black" className="text-center">
              <MDBCardBody style={{color:"#697b8c",}}>
            
            <p style={{whiteSpace:"pre-wrap",marginTop:"52px"}} >
            The website was developed using React js and php as the back end. The websited helped me to learn more about react js and its various advantages. It helped me to understand hpw to work with components, states and component lifecycles. I also leanred more on how to communicate with backend php using axios and how to utilise various node modules to enhance the perfomance of the website..</p>
             <a target="blank" href="http://jana1.losenviskas.com/">
             <MDBBtn tag="a" size="sm" floating gradient="blue" style={{padding:"8px",borderRadius:"12px"}}>
               
               visit website
             </MDBBtn></a>
             <br/>
              upgrade under construction
             <a target="blank" href="https://jana.losenviskas.com/build/">
              <MDBBtn tag="a" size="sm" floating gradient="red" style={mButton1}>
                visit webite
              </MDBBtn></a>
          </MDBCardBody>
         </MDBCard>
              </MDBCol>
            </MDBRow>
            <MDBRow >
              <MDBCol md="6">
              <h5 style={{paddingTop:"92px",paddingBottom:"22px"}}>Silai properties</h5>
                  <img className="img-fluid" style={{width:"99%",maxWidth: "387px"}} src={silai2}   />
              </MDBCol>
              <MDBCol md="6">
              <MDBCard color="white lighten-1" style={{boxShadow:"rgba(34, 94, 222, 0.08) 0px 32px 32px",maxWidth:"700px",marginLeft:"auto",marginRight:"auto",}} text="black" className="text-center">
              <MDBCardBody style={{color:"#697b8c",}}>
            
            
              <p style={{whiteSpace:"pre-wrap",marginTop:"52px"}} >
              The wesbite was built using html, javacript and php as the back end. I enhanced my understanding of Javascript by eploying various animations across the site, lerned more about ajax using the filter system employed and leanred more about responsive designs using bootstrap.
              <br></br>
              It helped me to improve skills associated with developing backend databases using sql. It also helped to improve my experience with online hosting platforms, learning hot to work with domain names and the development of custom emails.</p>
              <a target="blank" href="http://silaiproperties.net/">
              <MDBBtn tag="a" size="sm" floating gradient="blue" style={{padding:"8px",borderRadius:"12px"}}>
                
                visit website
              </MDBBtn></a>
          </MDBCardBody>
         </MDBCard>
              </MDBCol>
            </MDBRow>
            <MDBRow style={{marginTop:"72px"}}>
            <MDBCol md="6">
              <h5 style={{paddingTop:"12px",paddingBottom:"22px"}}>Losen Viskas</h5>
              <img className="img-fluid" style={{width:"99%",maxWidth: "587px"}} src={losen}  />
              </MDBCol>
              <MDBCol md="6">
              <MDBCard color="white lighten-1" style={{boxShadow:"rgba(34, 94, 222, 0.08) 0px 32px 32px",maxWidth:"700px",marginLeft:"auto",marginRight:"auto",}} text="black" className="text-center">
           <MDBCardBody style={{color:"#697b8c"}}> 
           <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >
             This website was developed using react js. It helped me to learn how to work with custom css styles to come up with different designs as well as working with various node modules to enhance the perfomance of the website.
              </p>

              <a target="blank" href="http://losenviskas.com/">
              <MDBBtn tag="a" size="sm" floating gradient="blue" style={{padding:"8px",borderRadius:"12px"}}>
                visit website
              </MDBBtn></a>
           </MDBCardBody>
         </MDBCard>
              </MDBCol>
            
            </MDBRow>
          </MDBContainer>
          <MDBContainer>
         
            
          <MDBRow style={{marginTop:"72px"}}>
              <MDBCol md="6">
              <h5 style={{paddingTop:"12px",paddingBottom:"22px"}}>LMG exhibitions</h5>
                  <img className="img-fluid" style={{width:"99%",maxWidth: "387px"}} src={LMG}  />
              </MDBCol>
              <MDBCol md="6">
              <MDBCard color="white lighten-1" style={{boxShadow:"rgba(34, 94, 222, 0.08) 0px 32px 32px",maxWidth:"700px",marginLeft:"auto",marginRight:"auto",}} text="black" className="text-center">
           <MDBCardBody style={{color:"#697b8c"}}> 
           <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >The website was developed using React js and php as the back end. The websited helped me to learn more about react js and its various advantages. It helped me to understand hpw to work with components, states and component lifecycles. I also leanred more on how to communicate with backend php using axios and how to utilise various node modules to enhance the perfomance of the website.</p>

           <a target="blank" href="http://lmg.losenviskas.com/">
              <MDBBtn tag="a" size="sm" floating gradient="blue" style={{padding:"8px",borderRadius:"12px"}}>
                visit website
              </MDBBtn></a>  
               <br/>
               upgrade under construction
             <a target="blank" href="http://lmgtemp3.lmglobalexhibitions.com/">
              <MDBBtn tag="a" size="sm" floating gradient="red" style={mButton1}>
                visit webite
              </MDBBtn></a>
           </MDBCardBody>
         </MDBCard>
              </MDBCol>
            </MDBRow>

            <MDBRow style={{marginTop:"72px"}}>
              <MDBCol md="6">
              <h5 style={{paddingTop:"12px",paddingBottom:"22px"}}>Restaurant</h5>
                  <img className="img-fluid" style={{width:"99%",maxWidth: "387px"}} src={RONIC}  />
              </MDBCol>
              <MDBCol md="6">
              <MDBCard color="white lighten-1" style={{boxShadow:"rgba(34, 94, 222, 0.08) 0px 32px 32px",maxWidth:"700px",marginLeft:"auto",marginRight:"auto",}} text="black" className="text-center">
           <MDBCardBody style={{color:"#697b8c"}}> 
           <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >THis is a sample website template for a restaurant showcasing different cuisines and menues for customers.</p>

           <a target="blank" href="http://ronicrestaurant.losenviskas.com/">
              <MDBBtn tag="a" size="sm" floating gradient="blue" style={{padding:"8px",borderRadius:"12px"}}>
                visit website
              </MDBBtn></a>
           </MDBCardBody>
         </MDBCard>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          <br/><br/>
            </div>
         ):(
          
      <MDBCard color="white lighten-1" style={{background: "linear-gradient(147deg, rgba(8, 0, 0, 0.01) 3%, #ffffff 3%, #ffffff 77%, #000000 4%)",width: "calc(100% - 112px)",margin:"auto",borderRadius: "4px",padding:"22px", boxShadow: "rgba(34, 94, 222, 0.08) 0px 32px 32px"}} text="black" className="text-center">
      <MDBCardBody style={{backgroundColor: "#fff"}}>
        <div {...dataSource.page} style={{marginLeft:"auto",marginRight:"auto",boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)"}} text="black" className="text-center">
        <MDBContainer>
       <MDBRow style={shad}>
        
         <MDBCol md="6"  style={shad1}>
         <h5 style={{paddingTop:"12px",paddingBottom:"22px"}}>Claudia</h5>
         {/* <div >  <img className="img-fluid" style={{width:"99%",maxWidth: "587px"}} src={silai2}  /></div> */}
        
<MDBCard color="white lighten-1" style={{ maxWidth:"700px",marginLeft:"auto",marginRight:"auto",marginTop:"32px"}} text="black" className="text-center">
          <MDBCarousel
            activeItem={1}
            length={4}
            showControls={true}
            showIndicators={false}
            className="z-depth-1"
            style={{height: "100",width: "100%",size: "cover",backgroundPosition: "center center"}}
          >
            <MDBCarouselInner>
              <MDBCarouselItem itemId="1">
                <MDBView >
                <div class="bgImg" style={{backgroundImage:`url(${claudia})`}}></div>
                </MDBView>
              </MDBCarouselItem>
              <MDBCarouselItem itemId="2">
                <MDBView >
                <div class="bgImg" style={{backgroundImage:`url(${claudia1})`}}></div>
                </MDBView>
              </MDBCarouselItem>
              <MDBCarouselItem itemId="3">
                <MDBView >
                <div class="bgImg" style={{backgroundImage:`url(${claudia2})`}}></div>
                </MDBView>
              </MDBCarouselItem>
              <MDBCarouselItem itemId="4">
                <MDBView >
                <div class="bgImg" style={{backgroundImage:`url(${claudia3})`}}></div>
                </MDBView>
              </MDBCarouselItem>

           
            </MDBCarouselInner>
    </MDBCarousel>
            {/* <MDBCardBody style={{color:"#697b8c"}}>
              <h5 style={{}}>Mechanical Ventilation</h5>
              <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >Design, installation and maintainance of ventillation systems</p>
            </MDBCardBody> */}
          </MDBCard>
         </MDBCol>
         <MDBCol md="6">
           <MDBCard color="white lighten-1" style={shad2} text="black" className="text-center">
           <MDBCardBody style={{color:"#697b8c",}}>
             
             <p style={{whiteSpace:"pre-wrap",marginTop:"52px"}} >
              The wesbite is an upgrade to an existing website. It is built using nuxt js, and laravel as the back end. In the project i chose nuxt for enhnced seo optimization. Laravel was used to build the backend for the admin dashboard used by the client to perform crud operations to the public website.
              <br></br>
           .</p>
           upgrade under construction
             <a target="blank" href="https://claudia.losenviskas.com/">
              <MDBBtn tag="a" size="sm" floating gradient="red" style={mButton1}>
                visit webite
              </MDBBtn></a>
              {/* <a target="blank" href="https://claudia.losenviskas.com/">
              <MDBBtn tag="a" size="sm" floating gradient="blue" style={{padding:"8px",borderRadius:"12px"}}>
                
                visit website
              </MDBBtn></a> */}
        
           </MDBCardBody>
         </MDBCard>
         </MDBCol>
       </MDBRow>
     </MDBContainer>
        <MDBContainer>
      
      <MDBRow style={shad}> 
      <MDBCol md="6">

          <MDBCard color="white lighten-1" style={shad2} text="black" className="text-center">
          <MDBCardBody style={{color:"#697b8c",}}>
            
            <p style={{whiteSpace:"pre-wrap",marginTop:"52px"}} >
            The website was developed using React js and php as the back end. The websited helped me to learn more about react js and its various advantages. It helped me to understand hpw to work with components, states and component lifecycles. I also leanred more on how to communicate with backend php using axios and how to utilise various node modules to enhance the perfomance of the website..</p>
             <a target="blank" href="http://jana1.losenviskas.com/">
             <MDBBtn tag="a" size="sm" floating gradient="blue" style={{padding:"8px",borderRadius:"12px"}}>
               
               visit website
             </MDBBtn></a>
             <br/>
              upgrade under construction
             <a target="blank" href="https://jana.losenviskas.com/build/">
              <MDBBtn tag="a" size="sm" floating gradient="red" style={mButton1}>
                visit webite
              </MDBBtn></a>
          </MDBCardBody>
        </MDBCard>
        </MDBCol>
      
      <MDBCol md="6"  style={shad1}>
        <h5 style={{paddingTop:"12px",paddingBottom:"22px"}}>Jana Engineering</h5>
        {/* <div >  <img className="img-fluid" style={{width:"99%",maxWidth: "587px"}} src={jana}  /></div> */}
      
<MDBCard color="white lighten-1" style={{ maxWidth:"700px",marginLeft:"auto",marginRight:"auto",marginTop:"32px"}} text="black" className="text-center">
          <MDBCarousel
            activeItem={1}
            length={4}
            showControls={true}
            showIndicators={false}
            className="z-depth-1"
            style={{height: "100",width: "100%",size: "cover",backgroundPosition: "center center"}}
          >
            <MDBCarouselInner>
              <MDBCarouselItem itemId="1">
                <MDBView >
                <div class="bgImg" style={{backgroundImage:`url(${jana})`}}></div>
                </MDBView>
              </MDBCarouselItem>
              <MDBCarouselItem itemId="2">
                <MDBView >
                <div class="bgImg" style={{backgroundImage:`url(${jana1})`}}></div>
                </MDBView>
              </MDBCarouselItem>
              <MDBCarouselItem itemId="3">
                <MDBView >
                <div class="bgImg" style={{backgroundImage:`url(${jana2})`}}></div>
                </MDBView>
              </MDBCarouselItem>
              <MDBCarouselItem itemId="4">
                <MDBView >
                <div class="bgImg" style={{backgroundImage:`url(${jana3})`}}></div>
                </MDBView>
              </MDBCarouselItem>

           
            </MDBCarouselInner>
    </MDBCarousel>
            {/* <MDBCardBody style={{color:"#697b8c"}}>
              <h5 style={{}}>Mechanical Ventilation</h5>
              <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >Design, installation and maintainance of ventillation systems</p>
            </MDBCardBody> */}
          </MDBCard>

        </MDBCol>
        
       
      </MDBRow>
    </MDBContainer>
   
     <MDBContainer>
       <MDBRow style={shad}>
         <MDBCol md="6" style={shad1}>
         <h5 style={{paddingTop:"12px",paddingBottom:"22px"}}>Losen Viskas</h5>
         <div style={{paddingBottom:"22px"}}> 
           <img className="img-fluid" style={{width:"99%",maxWidth: "387px"}} src={losen}  />
          
         </div> 
         </MDBCol>
         <MDBCol md="6">
         <MDBCard color="white lighten-1" style={shad2} text="black" className="text-center">
           <MDBCardBody style={{color:"#697b8c"}}>
             <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >
             This website was developed using react js. It helped me to learn how to work with custom css styles to come up with different designs as well as working with various node modules to enhance the perfomance of the website.
              </p>
              <a target="blank" href="http://losenviskas.com/">
              <MDBBtn tag="a" size="sm" floating gradient="blue" style={{padding:"8px",borderRadius:"12px"}}>
                visit website
              </MDBBtn></a>
           </MDBCardBody>
         </MDBCard>
         </MDBCol>
       </MDBRow>
       <MDBRow style={{marginTop:"72px"}}>
         <MDBCol md="6">
           <MDBCard color="white lighten-1" style={{boxShadow:"rgba(34, 94, 222, 0.08) 0px 2px 0px",maxWidth:"700px",marginLeft:"auto",marginRight:"auto",}} text="black" className="text-center">
           <MDBCardBody style={{color:"#697b8c"}}>
             <h5 style={{paddingTop:"12px",paddingBottom:"22px"}}>LMG Exhibitions</h5>
             <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >The website was developed using React js and php as the back end. The websited helped me to learn more about react js and its various advantages. It helped me to understand hpw to work with components, states and component lifecycles. I also leanred more on how to communicate with backend php using axios and how to utilise various node modules to enhance the perfomance of the website.</p>
          
             <a target="blank" href="http://lmg.losenviskas.com/">
              <MDBBtn tag="a" size="sm" floating gradient="blue" style={{padding:"8px",borderRadius:"12px"}}>
                visit website
              </MDBBtn></a>
              <br/>
              upgrade under construction
             <a target="blank" href="https://lmg.lmglobalexhibitions.com/dist/">
              <MDBBtn tag="a" size="sm" floating gradient="red" style={mButton1}>
                visit webite
              </MDBBtn></a>
           
           </MDBCardBody>
         </MDBCard>
         </MDBCol>
         <MDBCol md="6">
   
         <img className="img-fluid" style={{width:"99%",maxWidth: "487px"}} src={LMG}  />
         </MDBCol>
       </MDBRow>

       
       <MDBRow style={{marginTop:"72px"}}>
         
       <MDBCol md="6">
          <img className="img-fluid" style={{width:"99%",maxWidth: "487px"}} src={RONIC}  />
        </MDBCol>

         <MDBCol md="6">
           <MDBCard color="white lighten-1" style={{boxShadow:"rgba(34, 94, 222, 0.08) 0px 2px 0px",maxWidth:"700px",marginLeft:"auto",marginRight:"auto",}} text="black" className="text-center">
           <MDBCardBody style={{color:"#697b8c"}}>
             <h5 style={{paddingTop:"12px",paddingBottom:"22px"}}>Restaurant</h5>
             <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >THis is a sample website template for a restaurant showcasing different cuisines and menues for customers.</p>
          
             <a target="blank" href="http://ronicrestaurant.losenviskas.com/">
              <MDBBtn tag="a" size="sm" floating gradient="blue" style={{padding:"8px",borderRadius:"12px"}}>
                visit website
              </MDBBtn></a>
           
           </MDBCardBody>
         </MDBCard>
         </MDBCol>
       </MDBRow>

     </MDBContainer>
     <MDBContainer>
       <MDBRow style={shad}>
         <MDBCol md="6">
           <MDBCard color="white lighten-1" style={shad2} text="black" className="text-center">
           <MDBCardBody style={{color:"#697b8c",}}>
             
             <p style={{whiteSpace:"pre-wrap",marginTop:"52px"}} >
              The wesbite was built using html, javacript and php as the back end. I enhanced my understanding of Javascript by eploying various animations across the site, lerned more about ajax using the filter system employed and leanred more about responsive designs using bootstrap.
              <br></br>
              It helped me to improve skills associated with developing backend databases using sql. It also helped to improve my experience with online hosting platforms, learning hot to work with domain names and the development of custom emails.</p>
              <a target="blank" href="http://silaiproperties.net/">
              <MDBBtn tag="a" size="sm" floating gradient="blue" style={{padding:"8px",borderRadius:"12px"}}>
                
                visit website
              </MDBBtn></a>
        
           </MDBCardBody>
         </MDBCard>
         </MDBCol>
         <MDBCol md="6"  style={shad1}>
         <h5 style={{paddingTop:"12px",paddingBottom:"22px"}}>Silai properties</h5>
         <div >  <img className="img-fluid" style={{width:"99%",maxWidth: "587px"}} src={silai2}  /></div>
       
         </MDBCol>
       </MDBRow>
     </MDBContainer>
       </div>
       
        </MDBCardBody>
        </MDBCard>
  )}
        </div>
      </div>
        
{/* dummy */}

 
{/* 
    <img style={{width:"120px",borderRadius:"50%"}} src="http://lmgexhibitions.kilaqitu.com/img/a.gif" alt="img" />
     */}
    {/* <h5 type="mask-bottom" style={{color:this.state.mc}}>The best at events and exhibitions</h5> */}
    
        <TweenOne
          animation={{
            y: '-=20',
            yoyo: true,
            repeat: -1,
            duration: 1000,
          }}
          className="banner0-icon"
          key="icon"
        >
        </TweenOne>

{/* 
        <Footer0 style={{ background: "-moz-linear-gradient(315deg, #0cbaba 0%, #380036 74%)",background: "linear-gradient(315deg, #0cbaba 0%, #380036 74%)",}}
      
        dataSource={Footer00DataSource}
        isMobile={this.state.isMobile}
      /> */}
      </div>
      
    );
  }
}
const Banner = Form.create({ name: 'time_related_controls' })(Banner1);
export default Banner;
