import React from 'react';
import { Button, } from 'antd';
import TweenOne from 'rc-tween-one';

import "../../../node_modules/video-react/dist/video-react.css";
import { MDBCard, MDBCardBody, MDBMask,MDBCol,MDBRow,MDBCarousel,MDBContainer,MDBCarouselInner,MDBCarouselItem,MDBView,MDBCarouselCaption,MDBCardVideo,MDBCardTitle,MDBCardText,MDBBtn, element} from "mdbreact";
import { DefaultPlayer as Video } from 'react-html5video';
import '../../../node_modules/react-html5video/dist/styles.css';
import { Player } from 'video-react';
import splash from "../vid/splash.mp4";
import main from "../vid/main.mp4";
import details from "../vid/details.mp4";
import search from "../vid/search.mp4";
import map from "../vid/map.mp4";
import rate from "../vid/rate.mp4";
import gallery from "../vid/gallery.mp4";
import filters from "../vid/filters.mp4";


import  fabrication from "../img/1.jpg";
import  l1 from "../img/l1.png";
import  l2 from "../img/l2.png";

// import  im1 from "../img/im1.jpg";

import  im1 from "../img/7.jpg";
import  im2 from "../img/8.jpg";
import  im3 from "../img/3.jpg";
import  im4 from "../img/4.jpg";
import  im5 from "../img/5.jpg";
import  im6 from "../img/6.jpg";
import  im7 from "../img/7.jpg";
import  im8 from "../img/8.jpg";

import  vid1 from "../img/2.mp4";

import TextArea from "antd/lib/input/TextArea";
import Carousel from "react-multi-carousel";
import "../../../node_modules/react-multi-carousel/lib/styles.css";



// import Footer0 from './Footer0';
import '../Home/static/style';

// import {Footer00DataSource,} from './data.source';
import {Form, Input,Radio} from 'antd';
import { enquireScreen } from 'enquire-js';
let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
const axios = require('axios');
const ori="vertical";
let shad;
shad={
  // boxShadow: "rgba(34, 94, 222, 0.08) 0px 32px 32px",
  marginTop:"72px",
  paddingBottom:"22px"
}
let shad1={
  boxShadow: "rgba(34, 94, 222, 0.08) 0px 32px 32px"
}
let shad2={
  boxShadow: "rgba(34, 94, 222, 0.08) 0px 32px 32px",
  maxWidth:"700px",
  marginLeft:"auto",marginRight:"auto",
}
let smobi;
let smobi1;

if(isMobile){
   smobi={
    padding:"0px"
  }
  smobi1={
    paddingLeft:"3px",
    paddingRight:"13px"
  }
}
let rbg1;
rbg1={height:"70vh",width:"100%",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center",backgroundImage:"url("+im1+")"}

let rbg2;
rbg2={height:"70vh",width:"100%",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center",backgroundImage:"url("+im2+")"}

let rbg3;
rbg3={height:"70vh",width:"100%",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center",backgroundImage:"url("+im3+")"}
let rbg4;
rbg4={height:"70%",width:"100%",maxWidth: "587px",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center",backgroundImage:"url("+im4+")"}
let rbg5;
rbg5={height:"70vh",width:"100%",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center",backgroundImage:"url("+im5+")"}
let rbg6;
rbg6={height:"70vh",width:"100%",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center",backgroundImage:"url("+im6+")"}
let rbg7;
rbg7={height:"70vh",width:"100%",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center",backgroundImage:"url("+im7+")"}
let rbg8;
rbg8={height:"70vh",width:"100%",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center",backgroundImage:"url("+im8+")"}
if(isMobile){
  rbg1={height:"100vh",width:"100%",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center",backgroundImage:"url("+fabrication+")"}
  
  rbg1={height:"100vh",width:"100%",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center",backgroundImage:"url("+im1+")"}
  
  rbg2={height:"100vh",width:"100%",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center",backgroundImage:"url("+l2+")"}
}

const mButton = {
  display: "block",
  margin: "12px auto 0",
  background:" rgb(3, 67, 101)",
  background: "linear-gradient(to right, #034365 0%, #001b33 100%)",
  boxShadow:"0 8px 16px #0a52ab",
  // background: "-moz-linear-gradient(315deg, #3f0d12 0%, #a71d31 74%)",
  // background: "linear-gradient(315deg, #3f0d12 0%, #a71d31 74%)",
  // boxShadow: "rgba(38, 3, 3, 0.36) 1px 5px 5px",
  border: "none",
  transition:" background .45s @ease-out",
  width: "132px",
  lineHeight: "42px",
  height: "42px",
  borderRadius: "42px",


};
const responsive1 = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};
class Banner1 extends React.PureComponent {
  state = {
    st: "",
    mc:"white",
   
  }

  async onSubmit(e){
    e.preventDefault() 
    let res = await this.uploadFile(this.state.file);
    console.log(res.data);
    console.log(res.data.url);
    this.props.form.setFieldsValue({
      ['urli']: res.data.url,
  });
  if(this.state.imr & !res.data.error & res.data.error!=undefined || !this.state.imr){
    console.log("error"+res.data.error);
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        return;
      }
      const values = {
        fieldsValue
      };
      console.log('Received values of form: ', values);
      axios({
        method: 'post',
        // url: 'http://localhost/nw/vap/regApi.php?apicall=signup'
        url: 'http://lmgexhibitions.kilaqitu.com/php/lmg/api.php?apicall=quote',
        data: values,
        config: { headers: {'Content-Type': 'multipart/form-data' }}
    })
    .then((response) => {
      // console.log("response "+JSON.stringify(response));
      if(response.data.error){
        console.log("error "+response.data.message);
        this.setState({
          dbMessage: response.data.message
       });
        }else{
          if(response.data.fw){
            this.setState({
              ds: "none", 
              ds1: "block"
           });

          }
          console.log("msg "+response.data.message);
          this.setState({
            dbMessage: response.data.message
         });
        // var result=response.data.message;
        // JSON.stringify(result)
        // console.log("success "+JSON.stringify(result));
        }

    })
    .catch(function (response) {
        //handle error
        console.log("error"+response)
    });
    });
  }
}
  render() {
    const { ...currentProps } = this.props;
    const { dataSource } = currentProps;
    delete currentProps.dataSource;
    const { getFieldDecorator,} = this.props.form;
    // delete currentProps.isMobile;
    console.log("vid"+splash.currentTime);
  
    if(currentProps.isMobile){
   
this.setState({
  mc:"black",

})
    }
    return (
      <div {...currentProps} {...dataSource.wrapper} style={{height:this.state.st}}>
 


  
{/* dummy */}
{/* background: linear-gradient(146deg, rgb(90 25 35) 8%, black 8%, black 5%, white 9%, white 95%, black 96%, black 9%, rgb(90 25 35) 1%) center center / cover fixed; */}
{/* box-shadow: rgb(0 19 60) 0px 32px 32px; */}

<div {...dataSource.page} style={smobi1}>
<div  style={{background:"linear-gradient(146deg, rgb(27 34 41) 8%, black 8%, black 5%, white 9%, white 95%, black 96%, black 9%, rgb(27 34 41) 1%)",backgroundAttachment: "fixed",backgroundSize:"cover",backgroundPosition: "center",}}>

        {isMobile ? (
          <div {...dataSource.page} style={{marginLeft:"auto",marginRight:"auto",boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)"}} text="black" className="text-center">
         
          <MDBContainer>
              
       <MDBRow style={shad}>
         <MDBCol md="6">
           <MDBCard color="white lighten-1" style={shad2} text="black" className="text-center">
           <MDBCardBody style={{color:"#697b8c",}}>
             
         <p style={{padding:"13px",boxShadow:"0px 3px 5px 0px #989494", marginBottom:"12px"}}>Splash screen</p>
            <Video style={{maxWidth:"375px",height:"500px",margin:"auto"}} loop muted
                        controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                        poster="http://sourceposter.jpg"
                        onCanPlayThrough={(e) => {
                            // Do stuff
                            e.currentTime=30;
                        }}>
                        <source src={splash} type="video/webm" />
                      
                    </Video>
              
           </MDBCardBody>
         </MDBCard>
         </MDBCol>
         <MDBCol md="6">
           <MDBCard color="white lighten-1" style={shad2} text="black" className="text-center">
           <MDBCardBody style={{color:"#697b8c",}}>
             
         <p style={{padding:"13px",boxShadow:"0px 3px 5px 0px #989494", marginBottom:"12px"}}>Main Activity</p>
            <Video style={{maxWidth:"375px",height:"500px",margin:"auto"}} loop muted
                        controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                        poster="http://sourceposter.jpg"
                        onCanPlayThrough={(e) => {
                            // Do stuff
                            e.currentTime=30;
                        }}>
                        <source src={main} type="video/webm" />
                      
                    </Video>
              
           </MDBCardBody>
         </MDBCard>
         </MDBCol>
        
       </MDBRow>
       <MDBRow style={shad}>
           <MDBCol md="6">
           <MDBCard color="white lighten-1" style={shad2} text="black" className="text-center">
           <MDBCardBody style={{color:"#697b8c",}}>
             
         <p style={{padding:"13px",boxShadow:"0px 3px 5px 0px #989494", marginBottom:"12px"}}>Search Activity</p>
            <Video style={{maxWidth:"375px",height:"500px",margin:"auto"}} loop muted
                        controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                        poster="http://sourceposter.jpg"
                        onCanPlayThrough={(e) => {
                            // Do stuff
                            e.currentTime=30;
                        }}>
                        <source src={search} type="video/webm" />
                      
                    </Video>
              
           </MDBCardBody>
         </MDBCard>
         </MDBCol>
         
         <MDBCol md="6">
           <MDBCard color="white lighten-1" style={shad2} text="black" className="text-center">
           <MDBCardBody style={{color:"#697b8c",}}>
             
         <p style={{padding:"13px",boxShadow:"0px 3px 5px 0px #989494", marginBottom:"12px"}}>Details Activity</p>
            <Video style={{maxWidth:"375px",height:"500px",margin:"auto"}} loop muted
                        controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                        poster="http://sourceposter.jpg"
                        onCanPlayThrough={(e) => {
                            // Do stuff
                            e.currentTime=30;
                        }}>
                        <source src={details} type="video/webm" />
                      
                    </Video>
             
           </MDBCardBody>
         </MDBCard>
         </MDBCol>
         </MDBRow>
         
       <MDBRow style={shad}>
           <MDBCol md="6">
           <MDBCard color="white lighten-1" style={shad2} text="black" className="text-center">
           <MDBCardBody style={{color:"#697b8c",}}>
             
         <p style={{padding:"13px",boxShadow:"0px 3px 5px 0px #989494", marginBottom:"12px"}}>Filters</p>
            <Video style={{maxWidth:"375px",height:"500px",margin:"auto"}} loop muted
                        controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                        poster="http://sourceposter.jpg"
                        onCanPlayThrough={(e) => {
                            // Do stuff
                            e.currentTime=30;
                        }}>
                        <source src={filters} type="video/webm" />
                      
                    </Video>
              
           </MDBCardBody>
         </MDBCard>
         </MDBCol>
         
         <MDBCol md="6">
           <MDBCard color="white lighten-1" style={shad2} text="black" className="text-center">
           <MDBCardBody style={{color:"#697b8c",}}>
             
         <p style={{padding:"13px",boxShadow:"0px 3px 5px 0px #989494", marginBottom:"12px"}}>Gallery Activity</p>
            <Video style={{maxWidth:"375px",height:"500px",margin:"auto"}} loop muted
                        controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                        poster="http://sourceposter.jpg"
                        onCanPlayThrough={(e) => {
                            // Do stuff
                            e.currentTime=30;
                        }}>
                        <source src={gallery} type="video/webm" />
                      
                    </Video>
             
           </MDBCardBody>
         </MDBCard>
         </MDBCol>
         </MDBRow>
         
       <MDBRow style={shad}>
           <MDBCol md="6">
           <MDBCard color="white lighten-1" style={shad2} text="black" className="text-center">
           <MDBCardBody style={{color:"#697b8c",}}>
             
         <p style={{padding:"13px",boxShadow:"0px 3px 5px 0px #989494", marginBottom:"12px"}}>Rate</p>
            <Video style={{maxWidth:"375px",height:"500px",margin:"auto"}} loop muted
                        controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                        poster="http://sourceposter.jpg"
                        onCanPlayThrough={(e) => {
                            // Do stuff
                            e.currentTime=30;
                        }}>
                        <source src={rate} type="video/webm" />
                      
                    </Video>
              
           </MDBCardBody>
         </MDBCard>
         </MDBCol>
         
         <MDBCol md="6">
           <MDBCard color="white lighten-1" style={shad2} text="black" className="text-center">
           <MDBCardBody style={{color:"#697b8c",}}>
             
         <p style={{padding:"13px",boxShadow:"0px 3px 5px 0px #989494", marginBottom:"12px"}}>Maps Activity</p>
            <Video style={{maxWidth:"375px",height:"500px",margin:"auto"}} loop muted
                        controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                        poster="http://sourceposter.jpg"
                        onCanPlayThrough={(e) => {
                            // Do stuff
                            e.currentTime=30;
                        }}>
                        <source src={map} type="video/webm" />
                      
                    </Video>
             
           </MDBCardBody>
         </MDBCard>
         </MDBCol>
         </MDBRow>

          </MDBContainer>
          <br/><br/>
            </div>
         ):(

  <MDBCard color="white lighten-1" style={{background: "linear-gradient(147deg, rgba(8, 0, 0, 0.01) 3%, #ffffff 3%, #ffffff 77%, #000000 4%)",width: "calc(100% - 112px)",margin:"auto",borderRadius: "4px",padding:"22px", boxShadow: "rgba(34, 94, 222, 0.08) 0px 32px 32px"}} text="black" className="text-center">
  <MDBCardBody style={{backgroundColor: "#fff"}}>

        <div {...dataSource.page} style={{marginLeft:"auto",marginRight:"auto",boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)"}} text="black" className="text-center">
   
     <MDBContainer>
                
     <MDBRow style={shad}>
         <MDBCol md="6">
           <MDBCard color="white lighten-1" style={shad2} text="black" className="text-center">
           <MDBCardBody style={{color:"#697b8c",}}>
             
         <p style={{padding:"13px",boxShadow:"0px 3px 5px 0px #989494", marginBottom:"12px"}}>Splash screen</p>
            <Video style={{maxWidth:"375px",height:"500px",margin:"auto"}} loop muted
                        controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                        poster="http://sourceposter.jpg"
                        onCanPlayThrough={(e) => {
                            // Do stuff
                            e.currentTime=30;
                        }}>
                        <source src={splash} type="video/webm" />
                      
                    </Video>
              
           </MDBCardBody>
         </MDBCard>
         </MDBCol>
         <MDBCol md="6">
           <MDBCard color="white lighten-1" style={shad2} text="black" className="text-center">
           <MDBCardBody style={{color:"#697b8c",}}>
             
         <p style={{padding:"13px",boxShadow:"0px 3px 5px 0px #989494", marginBottom:"12px"}}>Main Activity</p>
            <Video style={{maxWidth:"375px",height:"500px",margin:"auto"}} loop muted
                        controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                        poster="http://sourceposter.jpg"
                        onCanPlayThrough={(e) => {
                            // Do stuff
                            e.currentTime=30;
                        }}>
                        <source src={main} type="video/webm" />
                      
                    </Video>
              
           </MDBCardBody>
         </MDBCard>
         </MDBCol>
        
       </MDBRow>
       <MDBRow style={shad}>
           <MDBCol md="6">
           <MDBCard color="white lighten-1" style={shad2} text="black" className="text-center">
           <MDBCardBody style={{color:"#697b8c",}}>
             
         <p style={{padding:"13px",boxShadow:"0px 3px 5px 0px #989494", marginBottom:"12px"}}>Search Activity</p>
            <Video style={{maxWidth:"375px",height:"500px",margin:"auto"}} loop muted
                        controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                        poster="http://sourceposter.jpg"
                        onCanPlayThrough={(e) => {
                            // Do stuff
                            e.currentTime=30;
                        }}>
                        <source src={search} type="video/webm" />
                      
                    </Video>
              
           </MDBCardBody>
         </MDBCard>
         </MDBCol>
         
         <MDBCol md="6">
           <MDBCard color="white lighten-1" style={shad2} text="black" className="text-center">
           <MDBCardBody style={{color:"#697b8c",}}>
             
         <p style={{padding:"13px",boxShadow:"0px 3px 5px 0px #989494", marginBottom:"12px"}}>Details Activity</p>
            <Video style={{maxWidth:"375px",height:"500px",margin:"auto"}} loop muted
                        controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                        poster="http://sourceposter.jpg"
                        onCanPlayThrough={(e) => {
                            // Do stuff
                            e.currentTime=30;
                        }}>
                        <source src={details} type="video/webm" />
                      
                    </Video>
             
           </MDBCardBody>
         </MDBCard>
         </MDBCol>
         </MDBRow>
         
       <MDBRow style={shad}>
           <MDBCol md="6">
           <MDBCard color="white lighten-1" style={shad2} text="black" className="text-center">
           <MDBCardBody style={{color:"#697b8c",}}>
             
         <p style={{padding:"13px",boxShadow:"0px 3px 5px 0px #989494", marginBottom:"12px"}}>Filters</p>
            <Video style={{maxWidth:"375px",height:"500px",margin:"auto"}} loop muted
                        controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                        poster="http://sourceposter.jpg"
                        onCanPlayThrough={(e) => {
                            // Do stuff
                            e.currentTime=30;
                        }}>
                        <source src={filters} type="video/webm" />
                      
                    </Video>
              
           </MDBCardBody>
         </MDBCard>
         </MDBCol>
         
         <MDBCol md="6">
           <MDBCard color="white lighten-1" style={shad2} text="black" className="text-center">
           <MDBCardBody style={{color:"#697b8c",}}>
             
         <p style={{padding:"13px",boxShadow:"0px 3px 5px 0px #989494", marginBottom:"12px"}}>Gallery Activity</p>
            <Video style={{maxWidth:"375px",height:"500px",margin:"auto"}} loop muted
                        controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                        poster="http://sourceposter.jpg"
                        onCanPlayThrough={(e) => {
                            // Do stuff
                            e.currentTime=30;
                        }}>
                        <source src={gallery} type="video/webm" />
                      
                    </Video>
             
           </MDBCardBody>
         </MDBCard>
         </MDBCol>
         </MDBRow>
         
       <MDBRow style={shad}>
           <MDBCol md="6">
           <MDBCard color="white lighten-1" style={shad2} text="black" className="text-center">
           <MDBCardBody style={{color:"#697b8c",}}>
             
         <p style={{padding:"13px",boxShadow:"0px 3px 5px 0px #989494", marginBottom:"12px"}}>Rate</p>
            <Video style={{maxWidth:"375px",height:"500px",margin:"auto"}} loop muted
                        controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                        poster="http://sourceposter.jpg"
                        onCanPlayThrough={(e) => {
                            // Do stuff
                            e.currentTime=30;
                        }}>
                        <source src={rate} type="video/webm" />
                      
                    </Video>
              
           </MDBCardBody>
         </MDBCard>
         </MDBCol>
         
         <MDBCol md="6">
           <MDBCard color="white lighten-1" style={shad2} text="black" className="text-center">
           <MDBCardBody style={{color:"#697b8c",}}>
             
         <p style={{padding:"13px",boxShadow:"0px 3px 5px 0px #989494", marginBottom:"12px"}}>Maps Activity</p>
            <Video style={{maxWidth:"375px",height:"500px",margin:"auto"}} loop muted
                        controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                        poster="http://sourceposter.jpg"
                        onCanPlayThrough={(e) => {
                            // Do stuff
                            e.currentTime=30;
                        }}>
                        <source src={map} type="video/webm" />
                      
                    </Video>
             
           </MDBCardBody>
         </MDBCard>
         </MDBCol>
         </MDBRow>

     </MDBContainer>
       </div>
        </MDBCardBody>
        </MDBCard>
  )}
        </div>
      </div>
        
{/* dummy */}

 
{/* 
    <img style={{width:"120px",borderRadius:"50%"}} src="http://lmgexhibitions.kilaqitu.com/img/a.gif" alt="img" />
     */}
    {/* <h5 type="mask-bottom" style={{color:this.state.mc}}>The best at events and exhibitions</h5> */}
    
        <TweenOne
          animation={{
            y: '-=20',
            yoyo: true,
            repeat: -1,
            duration: 1000,
          }}
          className="banner0-icon"
          key="icon"
        >
        </TweenOne>

{/* 
        <Footer0 style={{ background: "-moz-linear-gradient(315deg, #0cbaba 0%, #380036 74%)",background: "linear-gradient(315deg, #0cbaba 0%, #380036 74%)",}}
      
        dataSource={Footer00DataSource}
        isMobile={this.state.isMobile}
      /> */}
      </div>
      
    );
  }
}
const Banner = Form.create({ name: 'time_related_controls' })(Banner1);
export default Banner;
