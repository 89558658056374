import React, { Component } from "react";
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBCollapse, MDBNavItem, MDBNavLink, MDBIcon,} from 'mdbreact';

import { BrowserRouter as Router,Redirect } from "react-router-dom";
import Routes from "./Routes";
import { enquireScreen } from 'enquire-js';

import  pic from "./larsen/img/m.jpg";
let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
class App extends Component {
  state = {
    collapseID: "",
    redirect: true
    
  };

  toggleCollapse = collapseID => () =>
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));

  closeCollapse = collapseID => () => {
    window.scrollTo(0, 0);
    this.state.collapseID === collapseID && this.setState({ collapseID: "" });
  };


  render() {
    const container = { height: 1300 }
    const { redirect  } = this.state;
   
    return (
      <Router>
        <div className="flyout1" style={{backgroundColor: "#fff",fontFamily:"Verdana, Geneva, Tahoma, sans-serif"}}>
    
         
        <MDBNavbar style={{paddingTop:"1px",paddingBottom: "1px",  background: "black",background: "linear-gradient(147deg, #1b2229 15%, black 4%, black 87%, #1b2229 4%)",height:"52px"}} dark expand="md" fixed="top" scrolling>

            <MDBNavbarBrand href="/" style={{paddingTop:"7px",paddingBottom: "7px",color:"white" }}>
              <img src={pic} style={{ marginRight:"12px",borderRadius:"50%",width: "50px",height: "50px"}}/>
{/*               
                 <img src={pic} style={{ width: "4.0rem",marginRight:"12px",boxShadow:"rgb(249, 247, 247) 0px 1px 0px 0px, rgb(253, 251, 251) 0px 3px 0px 0px",marginTop:"-7px"}}/> */}
              
           
           
            </MDBNavbarBrand>
            
            <MDBNavbarToggler
            className="" 
            icon="location-arrow"
              onClick={this.toggleCollapse("mainNavbarCollapse")}
            >
              {/* <Icon type="menu-fold" /> */}
              {/* <MDBIcon icon="indent" /> */}
              <MDBIcon icon="ellipsis-v" />
            
            </MDBNavbarToggler>
            {isMobile ?(
            <MDBCollapse
              id="mainNavbarCollapse"
              isOpen={this.state.collapseID}
              navbar
              className="dark-grey-text"
              style={{backgroundColor:"black"}}
            >
                <MDBNavbarNav left onClick={this.toggleCollapse("mainNavbarCollapse")}>
                  
                <MDBNavItem active>
                    <MDBNavLink     to="/">Home</MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem >
                    <MDBNavLink   to="/websites">Websites</MDBNavLink>
                  </MDBNavItem>

                  <MDBNavItem>
                    <MDBNavLink      to="/app">Mobile application</MDBNavLink>
                  </MDBNavItem>

                  <MDBNavItem>
                    <MDBNavLink    to="/software">Windows software</MDBNavLink>
                  </MDBNavItem>
                  
                  <MDBNavItem>
                    <MDBNavLink  to="/game">Game</MDBNavLink>
                  </MDBNavItem>
                  
               
                </MDBNavbarNav>
                {/* <MDBNavbarNav right>
                  <MDBNavItem>
                    <MDBNavLink to="#"><MDBIcon fab icon="facebook-f" /></MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink to="#"><MDBIcon fab icon="twitter" /></MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink to="#"><MDBIcon fab icon="instagram" /></MDBNavLink>
                  </MDBNavItem>
                </MDBNavbarNav> */}
              </MDBCollapse>
 ):(

  <MDBCollapse
              id="mainNavbarCollapse"
              isOpen={this.state.collapseID}
              navbar
              className="dark-grey-text"
              style={{}}
            >
                <MDBNavbarNav left onClick={this.toggleCollapse("mainNavbarCollapse")}style={{marginLeft:"90px"}}>
                  
                <MDBNavItem active style={{marginLeft:"90px"}}>
                    <MDBNavLink   to="/">Home</MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem  style={{marginLeft:"90px"}}>
                    <MDBNavLink   to="/websites">Websites</MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem style={{marginLeft:"40px"}}>
                    <MDBNavLink    to="/app">Mobile application</MDBNavLink>
                  </MDBNavItem>

                  <MDBNavItem style={{marginLeft:"40px"}}>
                    <MDBNavLink      to="/software">Windows software</MDBNavLink>
                  </MDBNavItem>

                  <MDBNavItem style={{marginLeft:"40px"}}>
                    <MDBNavLink  to="/game">Game</MDBNavLink>
                  </MDBNavItem>
                 
                </MDBNavbarNav>
                {/* <MDBNavbarNav right>
                  <MDBNavItem>
                    <MDBNavLink to="#"><MDBIcon fab icon="facebook-f" /></MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink to="#"><MDBIcon fab icon="twitter" /></MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink to="#"><MDBIcon fab icon="instagram" /></MDBNavLink>
                  </MDBNavItem>
                </MDBNavbarNav> */}
              </MDBCollapse>
 )}
            </MDBNavbar>
         
           
        <div style={{ }}>
        <main >
       
            <Routes />
            <Redirect to='/'/>
          </main>
          </div>
          
        </div>
      </Router>
    );
  }
}

export default App;
