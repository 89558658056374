import React from "react";
import { Route, Switch } from "react-router-dom";

import websites from "./larsen/Home/index";

import mobile from "./larsen/Mobile/index";
import software from "./larsen/Software/index";
import game from "./larsen/gma";


import home from "./larsen/contact";
class Routes extends React.Component {
  render() {
    return (
      <Switch>
        
        
        <Route exact path ="/app"component={mobile}/>
        <Route exact path ="/software"component={software}/>
        <Route exact path ="/"component={home}/>
        
        <Route exact path ="/game"component={game}/>
        <Route exact path ="/websites"component={websites}/>
        <Route
          render={function () {
            return <Route exact path ="/"component={home}/>;
          }}
        />
      </Switch>
    );
  }
}

export default Routes;
